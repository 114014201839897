import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forecastingFilterObjValidation, forecastingFilterTypeCurveObjValidation } from '../../../Helper/validation';
import CartBasinBubble from '../../cartBasinToCounty/CartBasinBubble';
import { InputField } from '../InputField';
import { LINE_CHART_XAXIS_FILTERS, OIL, numberFormat, setFormData } from '../../../utils/helper';
import { forecastingData, handleForecastingData, handleSelectedForecastPoint } from '../../store/actions/wells-rigs-action';
import { ForecastingFormObj, ForecastingTypeCurveFormObj } from '../../models/redux-models';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Tooltip } from 'react-tooltip'

function ForecastFilter() {
    const {
        wellsAndRigs: {
            openForeCast,
            fullScrnAnalyticsType,
            analyticsData: {
                oil_data,
                gas_data,
                apiList,
                selectedForecastPoint,
                type,
                forecastingData: {
                    eur,
                    ai,
                    b,
                    qi,
                    tlim,
                    start_date_select,
                    peakmo,
                    dataList
                },
                xAxisFilter,
                xAxisFilterCum,
                normalized
            },
        }
    } = useAppSelector(state => state);

    const typeCurve = (type === "monthlyTab" && xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"]) || (type === "cumTab" && xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"]) ? true : false;

    const {
        // register,
        handleSubmit,
        // reset,
        // setValue,
        watch,
        control,
        setValue,
        reset,
        // setError,
        clearErrors,
        trigger,
        formState: { isValid }
    } = useForm<FieldValues>({
        resolver: yupResolver(typeCurve ? forecastingFilterTypeCurveObjValidation : forecastingFilterObjValidation),
        mode: "all",
    });

    const initialObj = {
        forecastType: [
            {
                id: 1,
                title: "Hyperbolic",
                active: true,
            },
            {
                id: 2,
                title: "Exponential",
                active: false,
            },
        ],
        initialProductionSolution: [
            {
                id: 1,
                title: "Variable",
                active: true,
            },
            {
                id: 2,
                title: "Fixed",
                active: false,
            },
        ],
        initialDeclineSolution: [
            {
                id: 1,
                title: "Variable",
                active: true,
            },
            {
                id: 2,
                title: "Fixed",
                active: false,
            },
        ],
        bFactorSolution: [
            {
                id: 1,
                title: "Variable",
                active: true,
            },
            {
                id: 2,
                title: "Fixed",
                active: false,
            },
        ],
        peak_solution: [
            {
                id: 1,
                title: "Peak Production",
                active: true,
            },
            {
                id: 2,
                title: "Fixed",
                active: false,
            },
        ]
    }
    const [state, setState] = useState(initialObj)
    const { forecastType, initialProductionSolution, initialDeclineSolution, bFactorSolution, peak_solution } = state;

    const handleForecastType = (id: number) => {
        let temp = forecastType.map((value) => {
            if (value.id === id) {
                return Object.assign(value, { active: true })
            } else {
                return Object.assign(value, { active: false })
            }
        });
        setState((prev) => ({
            ...prev,
            forecastType: temp,
        }));
        Array.isArray(dataList) && dispatch(handleForecastingData({ data: null }));
        setValue('ftype', temp.filter(_item => _item.active)[0]['id'] === 1 ? "hyp" : "exp");
        setValue('bmin', "0.1");
        setValue('bmax', "2.00");
        setValue('dlim', "6%");
        dlim_ref.current = "6";
        setValue('b_fixed', "");
        id === 2 && handleBFactorSolution(1);
        trigger()
        clearErrors(['bmin', "bmax", "b_fixed"])

    };

    const handleInitialProductionSolution = (id: number) => {
        let temp = initialProductionSolution.map((value) => {
            if (value.id === id) {
                return Object.assign(value, { active: true })
            } else {
                return Object.assign(value, { active: false })
            }
        }
        );
        setState((prev) => ({
            ...prev,
            initialProductionSolution: temp,
        }));

        setValue('qi_solution', temp.filter(_item => _item.active)[0]['id'] === 1 ? "variable" : "fixed");
        setValue('qi_fixed', "");
        trigger()
        clearErrors('qi_fixed')

    };

    const handleInitialDeclineSolution = (id: number) => {
        let temp = initialDeclineSolution.map((value) => {
            if (value.id === id) {
                return Object.assign(value, { active: true })
            } else {
                return Object.assign(value, { active: false })
            }
        }
        );
        setState((prev) => ({
            ...prev,
            initialDeclineSolution: temp,
        }));
        ai_fixed_ref.current = "";
        setValue('ai_solution', temp.filter(_item => _item.active)[0]['id'] === 1 ? "variable" : "fixed")
        setValue('ai_fixed', "");
        trigger()
        clearErrors('ai_fixed');

    };

    const handleBFactorSolution = (id: number) => {
        let temp = bFactorSolution.map((value) => {
            if (value.id === id) {
                return Object.assign(value, { active: true })
            } else {
                return Object.assign(value, { active: false })
            }
        }
        );
        setState((prev) => ({
            ...prev,
            bFactorSolution: temp,
        }));

        setValue('b_solution', temp.filter(_item => _item.active)[0]['id'] === 1 ? "variable" : "fixed")
        id !== 1 && setValue('bmin', "0.1");
        id !== 1 && setValue('bmax', "2.00");
        setValue('b_fixed', "");
        trigger()
        clearErrors(['bmin', "bmax", "b_fixed"])
    };

    const handlePeakSolution = (id: number) => {
        let temp = peak_solution.map((value) => {
            if (value.id === id) {
                return Object.assign(value, { active: true })
            } else {
                return Object.assign(value, { active: false })
            }
        }
        );
        setState((prev) => ({
            ...prev,
            peak_solution: temp,
        }));

        setValue('peak_solution', temp.filter(_item => _item.active)[0]['id'] === 1 ? "variable" : "fixed");
        // setValue('peak_month', "");
        trigger()
        // clearErrors('peak_month')

    };


    const dispatch = useAppDispatch();
    const { auth: { user: { access_token } }, } = useAppSelector(state => state);
    const ai_fixed_ref = useRef("");
    const dlim_ref = useRef("6");
    const onSubmit = (data: FieldValues) => {
        if (!selectedForecastPoint) {
            toast.info("Please select the points on the charts.")
            return
        }

        let tempData: (ForecastingFormObj | ForecastingTypeCurveFormObj)[] = [];
        !typeCurve && (fullScrnAnalyticsType === OIL ? oil_data : gas_data).forEach((item, index) => {
            item.values.forEach((_item) => {
                tempData.push({
                    "api": apiList[0],
                    "index": index,
                    "production_date": _item.production_date,
                    "production_quantity": _item.production_quantity,
                })
            })
        })

        typeCurve && (fullScrnAnalyticsType === OIL ? oil_data : gas_data).forEach((item) => {
            item.values.forEach((_item, index) => {
                tempData.push({
                    "producing_month": index,
                    "index": index,
                    // "production_quantity": _item.production_quantity,
                    "production_quantity_ft": _item.production_quantity
                })
            })
        })

        dispatch(forecastingData(access_token, {
            ...(!typeCurve && { "data": tempData }),
            ...(!typeCurve && { "sample_data": selectedForecastPoint ? selectedForecastPoint : [] }),
            ...(typeCurve && { "prod_well": tempData }),
            ...(typeCurve && {
                "prod_select": selectedForecastPoint ? selectedForecastPoint.map((item) => ({
                    "producing_month": item.producing_month,
                    // "production_quantity": item.production_quantity,
                    "production_quantity_ft": item.production_quantity
                })) : []
            }),
            "ftype": data.ftype,
            "qi_solution": data.qi_solution,
            "ai_solution": data.ai_solution,
            // "b_solution": data.b_solution,
            ...(data.ftype !== "exp" ? { "b_solution": data.b_solution } : {}),
            ...(((data.bmin && !(bFactorSolution[0]['active'] && forecastType[1]['active'])) || data.b_solution === "fixed") && { bmin: data.b_solution === "fixed" ? 0 : Number(data.bmin) }),
            ...(((data.bmax && !(bFactorSolution[0]['active'] && forecastType[1]['active'])) || data.b_solution === "fixed") && { bmax: data.b_solution === "fixed" ? 5 : Number(data.bmax) }),
            "wlife": Number(data.wlife),
            // ...(data.ftype !== "exp" ? { "dlim": ((Number(data.dlim) * 10) / 100) } : {}),
            // ...(data.ftype !== "exp" ? { "dlim": (Number(data.dlim.slice(0, data.dlim.length - 1)) / 100) } : {}),
            ...(data.ftype !== "exp" ? { "dlim": (Number(dlim_ref.current) / 100) } : {}),
            ...(data.qi_fixed && { qi_fixed: Number(data.qi_fixed) }),
            // ...(data.ai_fixed && { ai_fixed: ((Number(data.ai_fixed) * 1000) / 100) }),
            // ...(data.ai_fixed && { ai_fixed: (Number(data.ai_fixed.slice(0, data.ai_fixed.length - 1)) / 100) }),
            ...(ai_fixed_ref.current && { ai_fixed: (Number(ai_fixed_ref.current) / 100) }),
            ...(data.b_fixed && { b_fixed: Number(data.b_fixed) }),
            ...(typeCurve && { peak_solution: data.peak_solution }),
            ...(data.peak_month && typeCurve && { peak_month: Number(data.peak_month) }),
        }, typeCurve))
        // dispatch(handleSelectedForecastPoint({ data: null }))
    }

    const setFormValue = () => {
        setFormData({ ftype: "hyp", qi_solution: "variable", ai_solution: "variable", b_solution: "variable", dlim: "6%", bmin: "0.1", bmax: "2.00", ...(typeCurve && { peak_solution: "variable" }) }, setValue)
    }

    useEffect(() => {
        if (openForeCast) {
            setFormValue()
        }

        // eslint-disable-next-line
    }, [openForeCast])

    useEffect(() => {
        if (openForeCast && (fullScrnAnalyticsType === OIL ? oil_data.length : gas_data.length)) {
            sessionStorage.setItem("wlifMinValue", JSON.stringify(fullScrnAnalyticsType === OIL ? oil_data[0]['values'].length : gas_data[0]['values'].length))
        }
        return () => {
            sessionStorage.removeItem("wlifMinValue")
        }
        // eslint-disable-next-line
    }, [openForeCast, oil_data, gas_data])

    const activePeakSolution = useMemo(() => {
        const activeObj = peak_solution.filter((peakObj: { id: number; title: string; active: boolean }) => peakObj.active)
        if (activeObj.length > 0) {
            return activeObj[0].title
        }
        return 'Neither Peak or Fixed are Active'
    }, [peak_solution])

    const forecastTypeHTML = useMemo(() => {
        const activeObj = forecastType.filter((peakObj: { id: number; title: string; active: boolean }) => peakObj.active)
        if (activeObj.length > 0 && activeObj[0].title === "Hyperbolic") {
            return "<div><span><b>Hyperbolic: Used for cases where early production data shows a change in decline rate over time (most common).</b></span><br/></div>"
        }
        if (activeObj.length > 0 && activeObj[0].title === "Exponential") {
            return "<div><span><b>Exponential: Used for cases where all production data is declining at a constant rate over time.</b></span><br/></div>"
        }
        return ''
    }, [forecastType])

    const initialMonthlyDeclineHTML = useMemo(() => {
        const initialDeclineSolutionObj = initialDeclineSolution.filter((peakObj: { id: number; title: string; active: boolean }) => peakObj.active)
        if (initialDeclineSolutionObj.length > 0 && initialDeclineSolutionObj[0].title === "Variable") {
            return "<div><span><b>The effective decline rate between the initial decline month and the following month.</b></span><br/><span>Variable: Solver will return a best fit solution based on the selected data points.</span></div>"
        }
        if (initialDeclineSolutionObj.length > 0 && initialDeclineSolutionObj[0].title === "Fixed") {
            return "<div><span><b>The effective decline rate between the initial decline month and the following month.</b></span><br/><span>Fixed: Value provided by user.</span></div>"
        }
        return ''
    }, [initialDeclineSolution])

    const initialProductionHTML = useMemo(() => {
        const initialProductionSolutionObj = initialProductionSolution.filter((peakObj: { id: number; title: string; active: boolean }) => peakObj.active)
        if (initialProductionSolutionObj.length > 0 && initialProductionSolutionObj[0].title === "Variable") {
            return "<div><span><b>The monthly production value at the initial decline month.</b></span><br/><span>Variable : Solver will return a best fit solution based on the selected data points.</span></div>"
        }
        if (initialProductionSolutionObj.length > 0 && initialProductionSolutionObj[0].title === "Fixed") {
            return "<div><span><b>The monthly production value at the initial decline month.</b></span><br/><span>Fixed: Value provided by user.</span></div>"
        }
        return ''
    }, [initialProductionSolution])

    const bFactorHTML = useMemo(() => {
        const bFactorSolutionObj = bFactorSolution.filter((peakObj: { id: number; title: string; active: boolean }) => peakObj.active)
        if (bFactorSolutionObj.length > 0 && bFactorSolutionObj[0].title === "Variable") {
            return "<div><span><b>A dimensionless exponent in the hyperbolic decline equation. It determines the</b></span><br/><span><b>curvature of the production decline over time.</b></span><br/><span>The b factor typically ranges between 0 and 1 for most conventional reservoirs. However, for</span><br/><span>unconventional reservoirs, particularly in tight gas and shale formations, the b factor can</span><br/><span>exceed 1.</span><br/><span>Variable: Solver will return a best fit solution based on the selected data points.</span></div>"
        }
        if (bFactorSolutionObj.length > 0 && bFactorSolutionObj[0].title === "Fixed") {
            return "<div><span><b>A dimensionless exponent in the hyperbolic decline equation. It determines the</b></span><br/><span><b>curvature of the production decline over time.</b></span><br/><span>The b factor typically ranges between 0 and 1 for most conventional reservoirs. However, for</span><br/><span>unconventional reservoirs, particularly in tight gas and shale formations, the b factor can</span><br/><span>exceed 1.</span><br/><span>Fixed: Value provided by user.</span></div>"
        }
        return ''
    }, [bFactorSolution])

    if (!openForeCast) {
        return <></>
    }

    return (
        <div className={`${openForeCast ? "forcastingCon" : "d-none"}`}>
            <form
                className="form-block"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                autoCapitalize="off"
            >
                <div className="analytics-header">
                    <span>
                        {" "}
                        <img src="images/forcasting-icon.svg" alt="" />  {typeCurve ? "Type Curve" : "Forcasting"} Inputs
                    </span>{" "}
                </div>
                <div className="forcastingInside scrollSection">
                    <div className="formGroup">
                        <div className="row-point-5-gap">
                            <i data-tooltip-id="well-life-tooltip"
                                data-tooltip-html="<div><span><b>The total number of months from first production to end of forecast period.</b></span><br/></div>"
                                data-tooltip-place="top"
                                data-tooltip-variant="light">
                                <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                            </i>
                            <label htmlFor="">Well Life (months)</label>
                        </div>
                        <InputField
                            name={"wlife"}
                            type={"number"}
                            control={control}
                            showerror={true}
                            concatErrorMsg={watch("wlife") ? ` ${sessionStorage.getItem("wlifMinValue")}.` : ""}
                            className={
                                "form-control"
                            }
                            placeholder={
                                "Enter number of months"
                            }
                            defaultValue={"600"}
                        />
                    </div>
                    <div className="formGroup">
                        <ul className="types">
                            <CartBasinBubble
                                label="Forecast Type"
                                bubbleType={forecastType}
                                handleBubbleType={handleForecastType}
                                tooltipHTML={forecastTypeHTML}
                            />
                        </ul>
                    </div>
                    <div className={!typeCurve ? "d-none" : activePeakSolution === "Fixed" ? "formGroupMinBottom" : "formGroup"}>
                        <ul className="types">
                            <CartBasinBubble
                                label="Initial Decline Month"
                                bubbleType={peak_solution}
                                handleBubbleType={handlePeakSolution}
                                tooltipHTML="<div><span><b>Producing month where decline curve begins.</b></span><br/><span>Use Peak Production in cases where production increases during initial period.</span></div>"
                            />
                        </ul>
                    </div>
                    <div className={`formGroup ${activePeakSolution === "Fixed" && typeCurve ? "" : "d-none"}`}>
                        <InputField
                            name={"peak_month"}
                            type={"number"}
                            control={control}
                            showerror={true}
                            // twoDigitDecimal={true}
                            className={
                                "form-control"
                            }
                            placeholder={
                                "Enter fixed Peak month"
                            }
                            defaultValue={'0'}
                        />
                    </div>
                    <div className="formGroup">
                        <ul className="types">
                            <CartBasinBubble
                                label="Initial Production"
                                bubbleType={initialProductionSolution}
                                handleBubbleType={handleInitialProductionSolution}
                                tooltipHTML={initialProductionHTML}
                            />
                        </ul>
                    </div>
                    <div className={`formGroup ${initialProductionSolution[1]['active'] ? "" : "d-none"}`}>
                        {/* <label htmlFor="">Fixed qi</label> */}
                        <InputField
                            name={"qi_fixed"}
                            type={"number"}
                            control={control}
                            showerror={true}
                            className={
                                "form-control"
                            }
                            placeholder={
                                "Enter"
                            }
                            defaultValue={''}
                        />
                    </div>
                    <div className="formGroup">
                        <ul className="types">
                            <CartBasinBubble
                                label="Initial Monthly Decline"
                                bubbleType={initialDeclineSolution}
                                handleBubbleType={handleInitialDeclineSolution}
                                tooltipHTML={initialMonthlyDeclineHTML}
                            />
                        </ul>
                    </div>
                    <div className={`formGroup ${initialDeclineSolution[1]['active'] ? "" : "d-none"}`}>
                        {/* <label htmlFor="">Fixed ai</label> */}
                        <InputField
                            name={"ai_fixed"}
                            type={"number"}
                            control={control}
                            allowDecimal={true}
                            twoDigitDecimal={true}
                            onChangeHandler={(value) => {
                                ai_fixed_ref.current = value
                            }}
                            showerror={true}
                            extraInputValue={"%"}
                            className={
                                "form-control"
                            }
                            placeholder={
                                "Enter %"
                            }
                            defaultValue={''}
                        />
                    </div>
                    <div className={`formGroup ${forecastType[1]['active'] ? "d-none" : ""}`}>
                        {/* <div className={`formGroup`}> */}
                        <ul className="types">
                            <CartBasinBubble
                                label="b Factor"
                                bubbleType={bFactorSolution}
                                handleBubbleType={handleBFactorSolution}
                                tooltipHTML={bFactorHTML}
                            />
                        </ul>
                    </div>
                    <div className={`formGroup ${(bFactorSolution[1]['active']) ? "" : "d-none"}`}>
                        {/* <label htmlFor="">Fixed b</label> */}
                        <InputField
                            name={"b_fixed"}
                            type={"number"}
                            control={control}
                            showerror={true}
                            twoDigitDecimal={true}
                            className={
                                "form-control"
                            }
                            placeholder={
                                "Enter b Factor"
                            }
                            defaultValue={''}
                        />
                    </div>
                    <div className={`formGroup ${((bFactorSolution[0]['active'] && forecastType[1]['active']) || bFactorSolution[1]['active']) ? "d-none" : ""}`}>
                        <div className="row-point-5-gap">
                            <i data-tooltip-id="variable-b-factor-range-tooltip"
                                data-tooltip-html="<div><span><b>The range of b factors that may be returned by the solver.</b></span><br/><span>The b factor typically ranges between 0 and 1 for most conventional reservoirs. However, for</span><br/><span>unconventional reservoirs, particularly in tight gas and shale formations, the b factor can</span><br/><span>exceed 1.</span></div>"
                                data-tooltip-place="top"
                                data-tooltip-variant="light">
                                <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                            </i>
                            <label htmlFor="">Variable b Factor Range</label>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <InputField
                                    name={"bmin"}
                                    type={"number"}
                                    control={control}
                                    twoDigitDecimal={true}
                                    showerror={true}
                                    className={
                                        "form-control"
                                    }
                                    placeholder={
                                        "Minimum b"
                                    }
                                    defaultValue={bFactorSolution[0]['active'] ? '0.1' : ''}
                                />
                            </div>
                            <div className="col-md-6">
                                <InputField
                                    name={"bmax"}
                                    type={"number"}
                                    control={control}
                                    twoDigitDecimal={true}
                                    showerror={true}
                                    className={
                                        "form-control"
                                    }
                                    placeholder={
                                        "Maximum b"
                                    }
                                    defaultValue={bFactorSolution[0]['active'] ? '2.00' : ''}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`formGroup ${forecastType[1]['active'] ? "d-none" : ""}`}>
                        <div className="row-point-5-gap">
                            <i data-tooltip-id="limiting-annual-decline-tooltip"
                                data-tooltip-html="<div><span><b>The minimum permissible annual decline rate.</b></span><br/><span>When using a hyperbolic decline curve, the decline rate will continue to decrease over time –</span><br/><span>eventually reaching unreasonably low values. Limiting annual decline sets a “terminal” minimum</span><br/><span>decline rate for the forecast. Once the decline rate reaches this value, it remains constant for</span><br/><span>the remainder of the forecast. Values typically range between 4% and 8% with higher values </span><br/><span>representing a more conservative forecast.</span></div>"
                                data-tooltip-place="top"
                                data-tooltip-variant="light">
                                <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                            </i>
                            <label htmlFor="">Limiting Annual Decline</label>
                        </div>
                        <InputField
                            name={"dlim"}
                            type={"number"}
                            control={control}
                            showerror={true}
                            allowDecimal={true}
                            twoDigitDecimal={true}
                            onChangeHandler={(value) => {
                                dlim_ref.current = value
                            }}
                            extraInputValue={"%"}
                            className={
                                "form-control"
                            }
                            placeholder={
                                "Enter %"
                            }
                            defaultValue={''}
                        />
                    </div>
                    <div className={`formGroup ${(eur || ai || qi || b || tlim) ? "" : "d-none"}`}>
                        <label htmlFor="">Results</label>
                        <div className="resultlist">
                            <ul>
                                {/* <li>
                                    <strong>Remaining Reserves</strong>
                                    365,389.68
                                </li>
                                <li>
                                    <strong>Cum. Production</strong>
                                    239,848.63
                                </li> */}
                                <li className={typeCurve ? '' : "d-none"}>
                                    <div className="row-point-5-gap">
                                        <i data-tooltip-id="initial-decline-month-tooltip"
                                            data-tooltip-html="<div><span><b>The producing month where decline curve begins.<b></span><br/><span>Note: Production begins at month 0. For example, the fifth production month is month 4.</span></div>"
                                            data-tooltip-place="top"
                                            data-tooltip-variant="light">
                                            <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                                        </i>
                                        <strong>Initial Decline Month</strong>
                                    </div>
                                    {peakmo}
                                </li>

                                <li className={start_date_select ? '' : "d-none"}>
                                    <strong>Curve Start Date</strong>
                                    {moment(
                                        start_date_select
                                    ).format("MMM-DD-YYYY")}
                                </li>
                                <li className={qi !== "" ? '' : "d-none"} >
                                    <div className="row-point-5-gap">
                                        <i data-tooltip-id="initial-production-tooltip"
                                            data-tooltip-html="<div><span><b>Monthly production value at the initial decline month.<b></span><br/><span>Value returned by solver or provided by the user.</span></div>"
                                            data-tooltip-place="top"
                                            data-tooltip-variant="light">
                                            <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                                        </i>
                                        <strong>Initial Production</strong>
                                    </div>
                                    {normalized ? numberFormat.format(Number(qi)) : numberFormat.format(Math.trunc(Number(qi)))} {fullScrnAnalyticsType === OIL ? `${normalized ? "bbl/1000ft" : "bbl"}` : `${normalized ? "mcf/1000ft" : "mcf"}`}
                                </li>
                                <li className={b !== "" && forecastType[0]['active'] ? '' : "d-none"}>
                                    <div className="row-point-5-gap">
                                        <i data-tooltip-id="b-factor-tooltip"
                                            data-tooltip-html="<div><span><b>A dimensionless exponent in the hyperbolic decline equation. It determines the curvature of the production decline over time.<b></span><br/><span>Value returned by solver or provided by the user.</span></div>"
                                            data-tooltip-place="top"
                                            data-tooltip-variant="light">
                                            <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                                        </i>
                                        <strong>b Factor</strong>
                                    </div>
                                    {numberFormat.format(Number(b))}
                                </li>
                                <li className={ai !== "" ? '' : "d-none"}>
                                    <div className="row-point-5-gap">
                                        <i data-tooltip-id="initial-monthly-decline-tooltip"
                                            data-tooltip-html="<div><span><b>The effective decline rate between the initial decline month and the following month.<b></span><br/><span>Value returned by solver or provided by the user.</span></div>"
                                            data-tooltip-place="top"
                                            data-tooltip-variant="light">
                                            <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                                        </i>
                                        <strong>Initial Monthly Decline</strong>
                                    </div>
                                    {numberFormat.format(Number(ai))}%
                                </li>
                                <li className={tlim !== "" && forecastType[0]['active'] ? '' : "d-none"}>
                                    <div className="row-point-5-gap">
                                        <i data-tooltip-id="limiting-decline-tooltip"
                                            data-tooltip-html="<div><span><b>The producing month where production decline becomes limited by the Limiting Annual Decline.<b></span><br/><span>Note: Production begins at month 0. For example, the fifth production month is month four.</span></div>"
                                            data-tooltip-place="top"
                                            data-tooltip-variant="light">
                                            <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                                        </i>
                                        <strong>Limiting Decline Month</strong>
                                    </div>
                                    {numberFormat.format(Number(tlim))}
                                </li>
                                <li className={eur !== "" ? '' : "d-none"}>
                                    <div className="row-point-5-gap">
                                        <i data-tooltip-id="EUR-tooltip"
                                            data-tooltip-html="<div><span><b>The Estimated Ultimate Recovery.<b></span><br/><span>The total volume of liquids/gas recovered over the life of the well.</span></div>"
                                            data-tooltip-place="top"
                                            data-tooltip-variant="light">
                                            <img src="images/info-tooltip.svg" alt="tooltip" className="margin-bottom-point-5" />
                                        </i>
                                        <strong>EUR</strong>
                                    </div>
                                    <div><strong>{numberFormat.format(Number(eur))}</strong>{fullScrnAnalyticsType === OIL ? `${normalized ? " bbl/1000ft" : " bbl"}` : `${normalized ? " mcf/1000ft" : " mcf"}`}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-action">
                    <button className={`btn btn-forecast ${isValid ? "active" : ""}`} type={'submit'} disabled={!isValid}>{`${typeCurve ? "Run Type Curve" : "Run Forecast"}`}</button>
                    <button className="btn btn-outline" type={"button"} onClick={() => {
                        reset()
                        ai_fixed_ref.current = "";
                        dlim_ref.current = "6";
                        dispatch(handleForecastingData({ data: null }));
                        dispatch(
                            handleSelectedForecastPoint({
                                data: null,
                                doNotConCat: true
                            })
                        );
                        setState(() => ({ ...initialObj }))
                        setFormValue()
                    }}>Clear</button>
                </div>
            </form >
            <Tooltip id="initial-decline-month-tooltip" />
            <Tooltip id="EUR-tooltip" />
            <Tooltip id="initial-production-tooltip" />
            <Tooltip id="b-factor-tooltip" />
            <Tooltip id="initial-monthly-decline-tooltip" />
            <Tooltip id="limiting-decline-tooltip" />
            <Tooltip id="well-life-tooltip" />
            <Tooltip id="variable-b-factor-range-tooltip" />
            <Tooltip id="limiting-annual-decline-tooltip" />


        </div >
    )
}

export default ForecastFilter