import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks'
import LineChart from './LineChart';
import TabCont from './WellsAndRigs/TabCont';
import {
    handleAnalyticsData,
    handleAnalyticsProdFilters,
    handleForecast,
    handleForecastingData,
    handleFullScrnAnalytics, handleNormalize, handleSelectedForecastPoint, handleShowAndHideTable, toggleViewAnalytics
} from '../store/actions/wells-rigs-action';
import { DowData } from '../models/stateModel';
import {
    GAS, OIL, OPERATOR_PIE_CHART, PRODUCTION_DONUT_CHART, ANALYTICS_MONTHLY_TAB, capitalize, LINE_CHART_XAXIS_FILTERS, DropDownOption, ANALYTICS_CUM_TAB, LINE_CHART_MAX_ITEM_ALLOWED,

} from '../../utils/helper';
import { ActionType, ProductionDataObj, RigsDataObj, WellsAndPermitsObject, WellsRigsModel } from '../models/redux-models';
import ForecastFilter from './WellsAndRigs/ForecastFilter';
import { isInstanceOfWellsAndPermitsObject } from "../common/Map/helper"
import { Tooltip } from 'react-tooltip'
import DownloadProductionLink from '../aoi/DownloadProductionLink';

function AnalyticsFullScreen() {
    const { wellsAndRigs: { tabIndex, fullScrnAnalytics, selectedWellRigOrPermitList, fullScrnAnalyticsType, analyticsData: { oil_data, gas_data, type: analyticsProDataType, cum_gas_data, cum_oil_data, xAxisFilter, xAxisFilterCum, normalized, action, action_cum, apiListObj, apiListObjLength, monthlyDataLoading, cumDataLoading, forecastingData: {
        // dataList: forecastingData,
        forecastingCompleteDataFrame } }, donutChart: { dataList }, operatorPieChart: { operatorPieChartDataList }, showTable, openForeCast } } = useAppSelector(state => state);
    const dispatch = useAppDispatch();
    const [state, setState] = useState<{
        // dowData: DowData[], 
        showDropDownOption: boolean,
    }>({
        // dowData: [],
        showDropDownOption: false,
    })

    const {
        // dowData,
        showDropDownOption } = state;

    const typeCurve = (xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] || xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"]) ? true : false;

    const disableNormalize = useMemo(() => {
        // If one of the selected wells has a 0 lateral length then we should disable 'Normalize'
        if (selectedWellRigOrPermitList.map((well: WellsAndPermitsObject | ProductionDataObj | RigsDataObj) => !isInstanceOfWellsAndPermitsObject(well) ? 1 : well.lateral_length ? well.lateral_length : 0).includes(0)) {
            return true
        }
        return false
    }, [selectedWellRigOrPermitList])

    const notAllHorizontalWellsSelected = useMemo(() => {
        // If one of the selected wells has a 0 lateral length then we should disable 'Normalize'
        let notAllHor = false
        for (let well of selectedWellRigOrPermitList) {
            if (isInstanceOfWellsAndPermitsObject(well) && well.drill_type !== "Horizontal") {
                notAllHor = true
                break
            }
        }

        return notAllHor;
    }, [selectedWellRigOrPermitList])


    if (!fullScrnAnalytics) {
        return <></>
    }

    return (
        // forcastingOverlay
        <div
            className={`${fullScrnAnalytics ?
                `analyticFullgraph analyticFullGraphOverlap ${!showTable ? "analyticsFullScreenGraph" : ""} ${openForeCast ? `forcastingOverlay ${apiListObj.length === 1 ? "forecastingSingleDraw" : "typeCurveMultiDraw"}` : ""}`
                : "d-none"}`}
            onClick={() => {
                showDropDownOption && setState((prev) => ({ ...prev, showDropDownOption: false }))
            }}>
            <div className="card-box">
                <div className="card-box-header">
                    <div className="labelheading">{openForeCast ? `${apiListObj[0]['well_name']} - ${tabIndex === 1 ? (apiListObj as (RigsDataObj)[])[0]['api'] : (apiListObj as (WellsAndPermitsObject | ProductionDataObj)[])[0]["well_api"]} ` : ''}{capitalize(fullScrnAnalyticsType) === "Oil" ? "Liquids" : capitalize(fullScrnAnalyticsType)} Production {!typeCurve ? " Forecast" : "Type Curve"}</div>
                    <div className="action-btn">
                        <Link to="" onClick={() => {
                            dispatch(handleForecastingData({ data: null }));
                            dispatch(
                                handleSelectedForecastPoint({
                                    data: null,
                                    doNotConCat: true,
                                })
                            );
                            dispatch(handleForecast(false));


                            dispatch(handleShowAndHideTable(!showTable))
                            dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))
                            dispatch(toggleViewAnalytics());

                        }}>
                            <img
                                src="images/full.svg"
                                alt=""
                            />
                        </Link>
                        <DownloadProductionLink iconSize="small" />
                        <Link to="">
                            <img src="images/three-dots.svg" alt="" />
                        </Link>
                        <Link to="" onClick={() => {
                            dispatch(handleForecastingData({ data: null }));
                            dispatch(
                                handleSelectedForecastPoint({
                                    data: null,
                                    doNotConCat: true,
                                })
                            );
                            dispatch(handleForecast(false));
                            dispatch(handleShowAndHideTable(!showTable))
                            dispatch(handleFullScrnAnalytics({ fullScrnAnalytics: false }))

                        }}>
                            <img src="images/cross.svg" alt="" />
                        </Link>
                    </div>
                </div>
                <div className="box-inside">
                    <div className="normalize">

                        <div className={"aggregation"}>
                            Aggregation: <span className='cursor' onClick={() => setState((prev) => ({ ...prev, showDropDownOption: true }))}>{analyticsProDataType === ANALYTICS_MONTHLY_TAB ? capitalize(action) : capitalize(action_cum)}</span>
                            <div className={showDropDownOption ? "dropmenu" : "d-none"} >
                                <ul>
                                    {DropDownOption.map((item, index) => {
                                        return <li key={index}
                                            // className={
                                            //     typeCurve && openForeCast && item.value === "none" ? "d-none" :
                                            //         analyticsProDataType === ANALYTICS_MONTHLY_TAB ? action === item.value ? "selected" : "" : action_cum === item.value ? "selected" : ""
                                            // }
                                            className={`${item.value === "none" && selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? "disabled" : "cursor"} ${analyticsProDataType === ANALYTICS_MONTHLY_TAB && action === item.value ? "selected" : analyticsProDataType === ANALYTICS_CUM_TAB && action_cum === item.value ? "selected" : ''}`}
                                            onClick={(() => {
                                                if (item.value === "none" && selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED) {
                                                    return
                                                }
                                                if (typeCurve && openForeCast) {
                                                    dispatch(handleForecastingData({ data: null }));
                                                    dispatch(
                                                        handleSelectedForecastPoint({
                                                            data: null,
                                                            doNotConCat: true
                                                        })
                                                    );
                                                }
                                                dispatch(handleAnalyticsProdFilters({ action: item.value as ActionType, type: analyticsProDataType }));

                                                analyticsProDataType === ANALYTICS_MONTHLY_TAB && dispatch(handleAnalyticsData({ oilList: [], gasList: [], type: ANALYTICS_MONTHLY_TAB, monthlyDataLoading: true }));

                                                analyticsProDataType === ANALYTICS_CUM_TAB && dispatch(handleAnalyticsData({ oilList: [], gasList: [], type: ANALYTICS_CUM_TAB, cumDataLoading: true }));

                                                setState((prev) => ({ ...prev, showDropDownOption: false }));

                                            })}
                                        >{item.label}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="garph scrollSection">
                        {(fullScrnAnalyticsType === OIL || fullScrnAnalyticsType === GAS) ? analyticsProDataType === ANALYTICS_MONTHLY_TAB && monthlyDataLoading ? <div className="norecord">Loading...</div> : analyticsProDataType === ANALYTICS_CUM_TAB && cumDataLoading ? <div className="norecord">Loading...</div> : <LineChart usedFor={fullScrnAnalyticsType} yLabel={`${fullScrnAnalyticsType === OIL ? `${normalized ? "bbl/1000ft" : "bbl"}` : `${normalized ? "mcf/1000ft" : "mcf"}`}`} id={fullScrnAnalyticsType} useDateXAxis={(analyticsProDataType === ANALYTICS_MONTHLY_TAB ? xAxisFilter : xAxisFilterCum) === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? false : true} /> : <></>}

                        {/* {fullScrnAnalyticsType === PRODUCTION_DONUT_CHART ? <DonutChart data={dataList} /> : <></>} */}
                        {/* {fullScrnAnalyticsType === OPERATOR_PIE_CHART ? <PieChart data={operatorPieChartDataList} /> : <></>} */}
                    </div>
                    <TabCont onlyCheckedData={true} />
                </div>
            </div >
            <ForecastFilter />
            <Tooltip id="analytics-production-tooltip" />
            {disableNormalize || notAllHorizontalWellsSelected ? <Tooltip id="disable-normalize-tooltip" /> : ''}

        </div >
    )
}

export default AnalyticsFullScreen