import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../models/redux-models";
import {
    ANALYTICS_CUM_TAB, GAS, ANALYTICS_MONTHLY_TAB, OIL,
    LINE_CHART_XAXIS_FILTERS, getHeaderOfCSV,
} from "../../utils/helper";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { handleFreeTrialDownAlertMsgModal } from "../store/actions/modal-actions";
import { isInstanceOfWellsAndPermitsObject } from "../common/Map/helper"
import { getLineChartData, mergeAndSortOilGasData } from "../../Helper/commonFunction";

type PropsType = {
    iconSize: string;
}
type TypeForecastData = {
    well_api: string,
    production_date?: string,
    production_month?: number,
    'Liquids bbl/1000ft'?: number,
    'Liquids Stream bbl/1000ft'?: number,
    'Cum Liquids bbl/1000ft'?: number,
    'Cum Liquids Stream bbl/1000ft'?: number,
    'Liquids bbl/'?: number,
    'Liquids Stream bbl'?: number,
    'Cum Liquids bbl'?: number,
    'Cum Liquids Stream bbl'?: number,
    'Gas bbl/1000ft'?: number,
    'Gas Stream bbl/1000ft'?: number,
    'Cum Gas bbl/1000ft'?: number,
    'Cum Gas Stream bbl/1000ft'?: number,
    'Gas bbl/'?: number,
    'Gas Stream bbl'?: number,
    'Cum Gas bbl'?: number,
    'Cum Gas Stream bbl'?: number,
    DCA?: number,
    "Data Type": string
}
const DownloadProductionLink = (props: PropsType) => {
    const { iconSize } = props;
    const {
        wellsAndRigs: {
            analyticsData: {
                forecastingData: { dataList },
                oil_data,
                gas_data,
                type,
                cum_gas_data,
                cum_oil_data,
                xAxisFilter,
                xAxisFilterCum,
                normalized,
                action,
                action_cum,
                apiListObj,
                selectedForecastPoint,
            },
            openForeCast,
            selectedWellRigOrPermitList,
            tabIndex,
            fullScrnAnalyticsType,
        },
        auth: { user: { company_configs: { download_enabled, free_trial_period_enabled } } },
    } = useAppSelector((state) => state);

    const dispatch = useAppDispatch();
    const producingTime = (xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] || xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"]) ? true : false;

    return (
        <>

            <Link to="" onClick={() => {
                if (free_trial_period_enabled && !download_enabled) {
                    dispatch(handleFreeTrialDownAlertMsgModal(true))
                    return
                }
                let oilDataForDownload: any[] = getLineChartData(
                    dataList,
                    normalized,
                    tabIndex,
                    type,
                    ANALYTICS_MONTHLY_TAB,
                    oil_data,
                    cum_oil_data,
                    OIL,
                    OIL,
                    GAS,
                    gas_data,
                    cum_gas_data,
                    selectedWellRigOrPermitList,
                    selectedForecastPoint,
                    openForeCast,
                    type !== ANALYTICS_CUM_TAB ? false : xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? false : true,
                    action,
                    action_cum,
                    apiListObj)
                let gasDataForDownload = getLineChartData(
                    dataList,
                    normalized,
                    tabIndex,
                    type,
                    ANALYTICS_MONTHLY_TAB,
                    oil_data,
                    cum_oil_data,
                    GAS,
                    OIL,
                    GAS,
                    gas_data,
                    cum_gas_data,
                    selectedWellRigOrPermitList,
                    selectedForecastPoint,
                    openForeCast,
                    (type === ANALYTICS_MONTHLY_TAB && xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"]) || (type === ANALYTICS_CUM_TAB && xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"]) ? false : true,
                    action,
                    action_cum,
                    apiListObj)

                if (openForeCast) {
                    /** If open forecast is true, then we have a forecast or type curve situation. otherwise, its the
                     * standard, 'download all the production data'
                     */
                    const getProductionColumn = (stream: boolean) => {
                        if (fullScrnAnalyticsType === "oil") {
                            if (normalized && type === "monthlyTab") {
                                return `Liquids ${stream ? 'Stream ' : ''}bbl/1000ft`
                            } else if (normalized) {
                                return `Cum Liquids ${stream ? 'Stream ' : ''}bbl/1000ft`
                            } else if (type === "monthlyTab") {
                                return `Liquids ${stream ? 'Stream ' : ''}bbl`
                            } else {
                                return `Cum Liquids ${stream ? 'Stream ' : ''}bbl`
                            }
                        } else {
                            // produciton type is gas
                            if (normalized && type === "monthlyTab") {
                                return `Gas ${stream ? 'Stream ' : ''}mcf/1000ft`
                            } else if (normalized) {
                                return `Cum Gas ${stream ? 'Stream ' : ''}mcf/1000ft`
                            } else if (type === "monthlyTab") {
                                return `Gas ${stream ? 'Stream ' : ''}mcf`
                            } else {
                                return `Cum Gas ${stream ? 'Stream ' : ''}mcf`
                            }
                        }
                    }
                    let tempData: {
                        well_api: string,
                        production_date?: string,
                        production_month?: number,
                        'Liquids bbl/1000ft'?: number,
                        'Liquids Stream bbl/1000ft'?: number,
                        'Cum Liquids bbl/1000ft'?: number,
                        'Cum Liquids Stream bbl/1000ft'?: number,
                        'Liquids bbl/'?: number,
                        'Liquids Stream bbl'?: number,
                        'Cum Liquids bbl'?: number,
                        'Cum Liquids Stream bbl'?: number,
                        'Gas bbl/1000ft'?: number,
                        'Gas Stream bbl/1000ft'?: number,
                        'Cum Gas bbl/1000ft'?: number,
                        'Cum Gas Stream bbl/1000ft'?: number,
                        'Gas bbl/'?: number,
                        'Gas Stream bbl'?: number,
                        'Cum Gas bbl'?: number,
                        'Cum Gas Stream bbl'?: number,
                        DCA?: number,
                        "Data Type": string
                    }[] = [];

                    const createTempData = (originalData: any[], originalTempData: typeof tempData) => {
                        let hashObj: { [key: string]: TypeForecastData } = {}
                        if (originalData.length > 0 && originalData[0]?.values) {
                            let nonZero = false;
                            let index = 0;
                            let wellName = ''
                            let lateral_length = 0

                            selectedWellRigOrPermitList.forEach((well: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => {
                                if (isInstanceOfWellsAndPermitsObject(well) && well.well_name === originalData[0]?.name) {
                                    wellName = `${well.well_api} - ${well.well_name}`
                                    lateral_length = well.lateral_length
                                }
                            })
                            if (!wellName) {
                                wellName = originalData[0]?.name
                            }
                            originalData[0].values.forEach((oil_obj: { date: string, price: number, numValue: number }) => {
                                if (
                                    oil_obj.price === 0 &&
                                    !nonZero
                                    //  && lateral_length
                                ) {
                                } else {
                                    nonZero = true
                                    let newProdObj: any = { well_api: wellName }
                                    newProdObj[getProductionColumn(false)] = oil_obj.price.toFixed(2)
                                    newProdObj[getProductionColumn(true)] = oil_obj.price.toFixed(2)
                                    newProdObj["Data Type"] = "historical"
                                    const newHashObj = { ...hashObj }
                                    if (producingTime) {
                                        newProdObj.production_month = oil_obj.numValue
                                        newHashObj[oil_obj.numValue.toString()] = newProdObj
                                    } else {
                                        newProdObj.production_date = oil_obj.date
                                        newHashObj[oil_obj.date] = newProdObj
                                    }
                                    hashObj = newHashObj
                                }
                            })


                        }
                        if (dataList && dataList?.length > 0 && dataList[0]?.values) {
                            dataList[0].values.forEach((dataListObj: { date: string, price: number, numValue: number }) => {
                                let updatedHashObj: any = {}
                                if (producingTime) {
                                    updatedHashObj = { ...hashObj }[dataListObj.numValue] || {}
                                } else {
                                    updatedHashObj = { ...hashObj }[dataListObj.date] || {}
                                }

                                if (!updatedHashObj["Data Type"]) {
                                    let wellName = dataList[0].name
                                    selectedWellRigOrPermitList.forEach((well: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => {
                                        if (isInstanceOfWellsAndPermitsObject(well) && well.well_name === dataList[0].name) {
                                            wellName = `${well.well_api} - ${well.well_name}`
                                        }
                                    })
                                    updatedHashObj["Data Type"] = "forecast"
                                    updatedHashObj[getProductionColumn(true)] = dataListObj.price.toFixed(2)
                                    updatedHashObj.well_api = wellName
                                    if (producingTime) {
                                        updatedHashObj.production_month = dataListObj.numValue
                                    } else {
                                        updatedHashObj.production_date = dataListObj.date
                                    }

                                }
                                updatedHashObj.DCA = dataListObj.price.toFixed(2)
                                const newHashObj = { ...hashObj }
                                if (producingTime) {
                                    newHashObj[dataListObj.numValue.toString()] = updatedHashObj
                                } else {
                                    newHashObj[dataListObj.date] = updatedHashObj
                                }
                                hashObj = newHashObj

                            })
                        }
                        for (const [key, value] of Object.entries(hashObj)) {
                            originalTempData.push(value)
                        }
                        if (producingTime) {
                            originalTempData.sort((a: any, b: any) => a.production_month - b.production_month);
                        } else {
                            originalTempData.sort((a: any, b: any) => new Date(a.production_date).getTime() - new Date(b.production_date).getTime());
                        }
                        return originalTempData
                    }

                    // we're going to make a hash object with either the production_data or 
                    // production month as keys and the temp data as the value

                    if (fullScrnAnalyticsType === "oil") {
                        tempData = createTempData(oilDataForDownload, tempData)

                    } else {
                        // production type is gas
                        tempData = createTempData(gasDataForDownload, tempData)
                    }

                    const order = [
                        "production_date",
                        "production_month",
                        "well_api",
                        "Liquids bbl",
                        "Liquids bbl/1000ft",
                        "Cum Liquids bbl",
                        "Cum Liquids bbl/1000ft",
                        "Gas mcf",
                        "Gas mcf/1000ft",
                        "Cum Gas mcf",
                        "Cum Gas mcf/1000ft",
                        "DCA",
                        "Liquids Stream bbl",
                        "Liquids Stream bbl/1000ft",
                        "Gas Stream mcf",
                        "Gas Stream mcf/1000ft",
                        "Cum Liquids Stream bbl",
                        "Cum Liquids Stream bbl/1000ft",
                        "Cum Gas Stream mcf",
                        "Cum Gas Stream mcf/1000ft",
                        "Data Type"
                    ];

                    exportFromJSON({
                        data: tempData, fileName: `ED_Data_Export_${moment(new Date()).format(
                            "MMM-DD-YYYY, h:mm:ss a"
                        )}`,
                        exportType: "csv",
                        fields: [
                            (producingTime ? "production_month" : "production_date"),
                            "well_api",
                            getProductionColumn(false),
                            "DCA",
                            getProductionColumn(true),
                            "Data Type"
                        ],
                        beforeTableEncode: entries => {
                            return entries.sort((a, b) => {
                                return order.indexOf(a.fieldName) - order.indexOf(b.fieldName);
                            }).map(
                                ({ fieldName, fieldValues }) => {
                                    return { fieldName: getHeaderOfCSV(fieldName, false, normalized, type, action, action_cum), fieldValues };
                                }
                            )
                        }
                    })
                } else {
                    const mergeDataForDownload = mergeAndSortOilGasData(oilDataForDownload, gasDataForDownload, producingTime)
                    let tempData: { well_api: string, production_date: string, production_quantity_oil: string, production_quantity_gas: string, production_month?: number }[] = [];

                    // eslint-disable-next-line
                    mergeDataForDownload.forEach((item: any) => {
                        let nonZero = false;
                        let index = 0;
                        let wellName = ''
                        selectedWellRigOrPermitList.forEach((well: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => {
                            if (isInstanceOfWellsAndPermitsObject(well) && well.well_name === item.name) {
                                wellName = `${well.well_api} - ${item.name}`
                            }
                        })
                        if (!wellName) {
                            wellName = item.name
                        }

                        item.values.forEach((_item: any) => {
                            if (
                                (_item.price === 0 ||
                                    _item.price === null) &&
                                !nonZero
                            ) {
                            } else {
                                !nonZero && (nonZero = true);
                                tempData.push({
                                    well_api: wellName,
                                    production_date: `${_item.date}`,
                                    production_quantity_oil: _item.production_quantity_oil ? _item.production_quantity_oil.toString() : '',
                                    production_quantity_gas: _item.production_quantity_gas ? _item.production_quantity_gas.toString() : '',
                                    production_month: index
                                })
                                index = index + 1
                            }
                        })
                    })

                    exportFromJSON({
                        data: tempData, fileName: `ED_Data_Export_${moment(new Date()).format(
                            "MMM-DD-YYYY, h:mm:ss a"
                        )}`,
                        exportType: "csv",
                        fields: [
                            (producingTime ? "production_month" : "production_date"),
                            "well_api",
                            "production_quantity_oil",
                            "production_quantity_gas",
                        ],
                        beforeTableEncode: entries => entries.map(
                            ({ fieldName, fieldValues }) => {
                                return { fieldName: getHeaderOfCSV(fieldName, false, normalized, type, action, action_cum), fieldValues };
                            }
                        )
                    })
                }

            }}>
                {iconSize === "large" ? (<div className="downloadLink">
                    <img src="images/download.svg" alt="downloadImage" className="downloadImage" />
                </div>) : (<img src="images/download.svg" alt="downloadImage" />)}

            </Link >

        </>
    );
};

export default DownloadProductionLink;
